.dx-tagbox-popup-wrapper {
    .dx-list-select-all {
        padding: 7px 20px !important;
        &.dx-list-item-selected {
            background-color: #e6e6e6 !important;
        }
    }

    .dx-item {
        padding: 5px 20px !important;

        &:nth-child(odd) {
            background-color: #fbfbfc;
        }

        &.dx-list-item-selected {
            background-color: #e6e6e6 !important;
        }
    }
}
