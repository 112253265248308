/* You can add global styles to this file, and also import other style files */
@import './styles/dx/dx-tag-box';

//region Fonts
@font-face {
    font-family: 'AvenirLTStd-Book';
    src: url('assets/fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/AvenirLTStd-Book.otf') format('opentype'),
        url('assets/fonts/AvenirLTStd-Book.woff') format('woff'),
        url('assets/fonts/AvenirLTStd-Book.ttf') format('truetype'),
        url('assets/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirLTStd-Medium';
    src: url('assets/fonts/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/AvenirLTStd-Medium.otf') format('opentype'),
        url('assets/fonts/AvenirLTStd-Medium.woff') format('woff'),
        url('assets/fonts/AvenirLTStd-Medium.ttf') format('truetype'),
        url('assets/fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirLTStd-Heavy';
    src: url('assets/fonts/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/AvenirLTStd-Heavy.woff') format('woff'),
        url('assets/fonts/AvenirLTStd-Heavy.ttf') format('truetype'),
        url('assets/fonts/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirLTStd-Black';
    src: url('assets/fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/AvenirLTStd-Black.otf') format('opentype'),
        url('assets/fonts/AvenirLTStd-Black.woff') format('woff'),
        url('assets/fonts/AvenirLTStd-Black.ttf') format('truetype'),
        url('assets/fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}

//endregion

//region Constants
@w: #fff;
@b: #000;
/*@dark-gray: #343434;
@light-gray: #a0a0a0;*/
@dark-gray: #222;
@light-gray: #666;
@lighter-gray: #cecece;
@border-gray: #cfd3dd;
@border-light-gray: #d6d9dc;
@blue: #0096ff;
@red: #ff375d;
@green: #69dc57;
@purple: #aa6cb3;
@pink: #ff3262;
@yellow: #949186;
//endregion

// dark theme constants

@dark-black: #16191c;
@light-black: #22272b;
@box-black: #2e3439;

// Availability weekend colors
@availability-left: #939393;
@availability-right: #414141;

/**
* Color variables for calendar events and bookings.
* Be mindfull that when changing these there is a
* representation of them in the CalendarLegendColor Model
* used in the calendar component. Also these are used in the
* local style fo the component
*/
@calendar-events: #bcdfcf;
@calendar-to-arrive: #f2be53;
@calendar-checked-in: #55c1f0;
@calendar-checked-out: #cccccc;
@calendar-enquired: #c19777;
@calendar-unavailable: #ff5577;
@calendar-maintenance: #ffac74;
@calendar-function-available: #e5cbea;
@calendar-function-booked: @purple;
@calendar-function-unavailable: #f8a1a4;
@warningInfoError: #ff0000;
@warningInfoWarning: #ff8c00;
//endregion
/* Microsoft EdgeV13&14 CSS styles go here */

//region Defaults
.flex {
    display: flex;
}

.flex-end {
    justify-content: flex-end;
}

.left {
    float: left;
}

.right {
    float: right;
}

.inline {
    display: inline;
}

.noscroll {
    overflow: hidden;
}

html {
    height: 100%;
}

.main {
    flex: 1;
}

.dx-popup-wrapper.dx-popover-wrapper > .dx-overlay-content {
    background-color: @blue;
    color: @w;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

.housekeeping-wrapper {
    .dx-texteditor-input {
        font-family: inherit !important;
        cursor: inherit !important;
    }
}

.dialog-controls-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    table-layout: fixed;
    margin: 0;
    font-size: 14px;
    color: @dark-gray;
    font-family: 'AvenirLTStd-Book', sans-serif;
    background-color: #e6e6e6;

    &.dark-theme {
        background-color: @light-black;
        color: @w;

        .calendar-wrapper {
            .legend {
                .legend-item {
                    color: @w !important;
                }
            }

            .month-wrapper {
                .prev,
                .next {
                    color: @dark-black !important;

                    &:hover {
                        color: @w !important;
                    }
                }
            }
        }

        .partners-wrapper {
            .prev,
            .next {
                color: @dark-black !important;

                &:hover {
                    color: @w !important;
                }
            }
        }

        dx-text-box,
        dx-select-box,
        dx-date-box {
            background-color: #373e44;
            border: none !important;
            color: #fff !important;

            .dx-texteditor-input {
                background-color: #373e44 !important;
                border: none;
                color: #fff !important;
            }
        }

        .dx-dropdowneditor-icon {
            color: #fff !important;
        }

        .dx-dropdowneditor.dx-state-hover .dx-dropdowneditor-icon {
            background-color: @light-black;
            color: #fff !important;
        }

        dx-data-grid .dx-toolbar .dx-toolbar-after .dx-datagrid-search-panel {
            background-color: transparent;
            border: none;
        }

        dx-data-grid .dx-texteditor-container .dx-texteditor-input {
            background-color: #363e44;
            color: #fff;
            visibility: hidden;
        }

        .dx-datagrid-rowsview .dx-selection.dx-row > td.dx-focused,
        .dx-datagrid-rowsview .dx-selection.dx-row:hover > td.dx-focused {
            background-color: #22272b !important;
        }

        .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
        .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td
        {
            background-color: #363e44;
        }

        .form-item {
            .custom-input,
            .custom-textarea,
            dx-tag-box {
                background-color: #373e44;
                border: none !important;
                color: #fff;
            }

            dx-text-box,
            dx-select-box,
            dx-date-box {
                background-color: #373e44;
                border: none !important;
                color: #fff !important;

                .dx-texteditor-input {
                    background-color: #373e44 !important;
                    border: none;
                    color: #fff !important;
                }
            }

            .custom-label {
                color: #8693a1;
            }

            .dx-dropdowneditor-icon {
                color: #fff !important;
            }

            .dx-dropdowneditor.dx-state-hover .dx-dropdowneditor-icon {
                background-color: @light-black;
                color: #fff !important;
            }
        }

        .input-group {
            input {
                padding: 10px 12px;
                border-radius: 3px;
                border: none;
                font-size: 14px;
                font-family: 'AvenirLTStd-Heavy', sans-serif;
                background-color: #373e44;
                color: #fff;
                outline: none;
                width: ~'calc(100% - 26px)';

                &::-ms-clear {
                    display: none;
                }
            }

            .dropbtn {
                background-color: #2f3439;
            }
        }
    }
}

.rate-plans-wrapper {
    .overlay {
        .no-padding-input {
            .custom-select {
                padding: 0;
            }
        }
    }
}

.no-padding-input {
    .custom-select {
        padding: 0;
    }
}

.no-focus-wrapper {
    dx-data-grid .dx-datagrid .dx-column-lines > td {
        padding: 8px 5px !important;
        vertical-align: middle;
    }
}

header {
    .property-select {
        .dx-texteditor-input {
            color: @light-gray !important;
        }
    }
}

.smaller-cell {
    .dx-datagrid-content .dx-datagrid-table .dx-row > td {
        padding: 3px 1px !important;
    }
}

.last-row-colored {
    .dx-datagrid {
        .dx-datagrid-rowsview {
            .dx-datagrid-content {
                .dx-datagrid-table {
                    tr:last-child {
                        background-color: #fbfbfc;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.custom-box {
    &.dark-theme {
        .last-row-colored {
            .dx-datagrid {
                .dx-datagrid-rowsview {
                    .dx-datagrid-content {
                        .dx-datagrid-table {
                            tr:last-child {
                                background-color: #2c3237;
                            }
                        }
                    }
                }
            }
        }
    }
}

.guest-uncompleted {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -5px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #fff;
        transform: rotate(135deg);
        opacity: 0.7;
        clear: both;
    }
}

.dx-dropdowneditor-button-visible .dx-texteditor-input {
    color: @light-gray !important;
    font-family: 'AvenirLTStd-Book', sans-serif !important;
}

*:focus {
    outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

table {
    border-spacing: 0;
}

.sub-menu-lvl2 {
    margin-left: 20px;
    //background-color: #0096ff;
}

.custom-link {
    text-decoration: underline;
    color: @blue;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.custom-box {
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: @w;

    &.tabs {
        box-shadow: none;
        background-color: transparent;
    }

    &.dark-theme {
        background-color: @box-black;
        color: @w;

        .dx-datagrid {
            background-color: @box-black;
        }

        .dx-toolbar {
            background-color: @box-black;
        }

        dx-data-grid .dx-datagrid .dx-datagrid-headers {
            background-color: #2c3237;
            border-top: 1px solid #373e44;
            border-bottom: 1px solid #373e44;
        }

        dx-data-grid {
            .dx-datagrid-rowsview {
                border: none;

                .dx-row {
                    &:nth-child(even) {
                        background-color: #2c3237;
                    }
                }
            }

            .dx-datagrid .dx-datagrid-headers td {
                color: #fff !important;
                border-bottom: 1px solid #373e44 !important;
            }

            .dx-datagrid .dx-datagrid-table td {
                color: #fff !important;
                border-bottom: 1px solid #373e44;
            }
        }

        .dx-datagrid-pager.dx-widget {
            color: #8693a1 !important;
        }

        .dx-pager .dx-pages .dx-selection,
        .dx-pager .dx-page-sizes .dx-selection {
            color: @dark-black;
        }

        .dx-datagrid-table
            .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
            > td:not(.dx-focused) {
            background-color: transparent !important;
        }

        .change-order:hover {
            background-color: #171a1d;
        }

        .name {
            color: #fff;
        }

        dx-text-box,
        dx-date-box,
        dx-select-box {
            background-color: #373e44 !important;
            border: none;
            color: #fff;

            .dx-texteditor-input {
                background-color: #373e44;
                border: none;
                color: #fff !important;
            }
        }

        .dx-dropdowneditor-icon {
            color: #fff !important;
        }

        .dx-dropdowneditor.dx-state-hover .dx-dropdowneditor-icon {
            background-color: @light-black;
            color: #fff !important;
        }

        .form-item {
            .custom-input,
            .custom-textarea,
            dx-tag-box {
                background-color: #373e44;
                border: none;
                color: #fff;
            }

            dx-text-box,
            dx-select-box,
            dx-date-box {
                background-color: #373e44;
                border: none;
                color: #fff;

                .dx-texteditor-input {
                    background-color: #373e44;
                    border: none;
                    color: #fff !important;
                }
            }

            .custom-label {
                color: #8693a1;
            }

            .dx-dropdowneditor-icon {
                color: #fff !important;
            }

            .dx-dropdowneditor.dx-state-hover .dx-dropdowneditor-icon {
                background-color: @light-black;
                color: #fff !important;
            }
        }
    }


  }

    .table-holder {
        .custom-table {
            width: 100%;

            .table-header {
                background: #fbfbfc;

                th {
                    text-align: left;
                    padding: 7px 5px;
                    border-bottom: 1px solid #eef1f5;
                    color: #222;
                    white-space: nowrap;
                    font-family: 'AvenirLTStd-Book', sans-serif;
                    text-transform: uppercase;
                }
            }

            tr {
                &.colored {
                    background: #fbfbfc;
                }

                td {
                    padding: 12px 5px;
                    color: #666;
                    border-bottom: 1px solid #eef1f5;
                }
            }
        }
    }


//testing comment
.form-box {
    &.dark-theme {
        @background-color: @box-black !important;

        .form-item {
            .custom-label {
                //color: #8693a1 !important;
            }

            .custom-input,
            .custom-textarea,
            dx-tag-box {
                background-color: #373e44;
                border: none;
                color: #fff;
            }

            dx-text-box,
            dx-select-box,
            dx-date-box {
                background-color: #373e44;
                border: none;
                color: #fff;

                .dx-texteditor-input {
                    background-color: #373e44;
                    border: none;
                    color: #fff !important;
                }
            }

            .custom-label {
                color: #8693a1;
            }

            .dx-dropdowneditor-icon {
                color: #fff !important;
            }

            .dx-dropdowneditor.dx-state-hover .dx-dropdowneditor-icon {
                background-color: @light-black;
                color: #fff !important;
            }
        }

        .dx-datagrid {
            background-color: @box-black;
        }

        .dx-toolbar {
            background-color: @box-black;
        }

        dx-data-grid .dx-datagrid .dx-datagrid-headers {
            background-color: #2c3237;
            border-top: 1px solid #373e44;
            border-bottom: 1px solid #373e44;
        }

        dx-data-grid {
            .dx-datagrid-rowsview {
                border: none;

                .dx-row {
                    &:nth-child(even) {
                        background-color: #2c3237;
                    }
                }
            }

            .dx-datagrid .dx-datagrid-headers td {
                color: #fff !important;
                border-bottom: 1px solid #373e44 !important;
            }

            .dx-datagrid .dx-datagrid-table td {
                color: #fff !important;
                border-bottom: 1px solid #373e44;
            }
        }

        .dx-datagrid-pager.dx-widget {
            color: #8693a1 !important;
        }

        .dx-datagrid-table
            .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
            > td:not(.dx-focused) {
            background-color: transparent !important;
        }
    }
}

// DARK THEME STYLING - more styles are in custom-box and components

.dark-theme {
    .custom-box .tab-input:checked + label {
        color: #fff !important;
        background-color: #2e3439 !important;
    }

    .custom-box .tab-input + label {
        color: #8693a1 !important;
    }

    dx-tag-box.dx-texteditor {
        background-color: #373e44;
        border: none;
        color: #fff;
    }

    //dx-date-box,.custom-select {
    //  background: transparent!important;
    //}

    #tab1:checked ~ .t1:before,
    #tab2:checked ~ .t2:before,
    #tab3:checked ~ .t3:before,
    #tab4:checked ~ .t4:before {
        background-color: @box-black !important;
    }

    .tab {
        color: #8693a1 !important;

        &.active,
        &:before {
            background-color: @box-black !important;
            color: @w !important;
        }
    }

    .dx-accordion-item-title:before {
        color: #fff;
    }

    .dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
        background-color: @light-black;
    }

    .dx-scheduler-group-table tr:first-of-type,
    .dx-scheduler-date-table tr:first-of-type,
    .dx-scheduler-group-table tr:nth-child(odd),
    .dx-scheduler-date-table tr:nth-child(odd),
    .calendar-scheduler .dx-scheduler-work-space,
    .calendar-scheduler .dx-scrollable-container {
        background-color: @box-black;
    }

    .dx-scheduler-group-table tr:last-of-type,
    .dx-scheduler-date-table tr:last-of-type {
        background-color: @light-black;
    }

    .dx-scheduler-header-panel-cell,
    .dx-scheduler-timeline .dx-scheduler-group-header .dx-scheduler-group-header-content div {
        color: #fff;
    }

    .calendar-wrapper .calendar-total {
        background-color: @dark-black !important;

        div {
            color: #fff !important;
        }
    }

    .dx-datagrid-rowsview .dx-selection.dx-row > td,
    .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
        background-color: @light-black;
    }

    .calendar-scheduler .dx-scheduler-header {
        background-color: @dark-black;
    }

    .overlay {
        background-color: rgba(34, 39, 43, 0.95) !important;
    }

    .add-new-box {
        border: none;
        background-color: #373e44;

        .img {
            background-color: @light-black;
        }

        span {
            color: #8693a1;
        }

        &:hover {
            .img {
                background-color: @dark-black;
            }
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(180, 180, 180, 0.95);
    z-index: 2;
}

.form-item {
    position: relative;

    .custom-label {
        margin-bottom: 6px;
        color: @dark-gray;
        font-family: 'AvenirLTStd-Heavy', sans-serif;
    }

    .custom-input {
        padding: 9px 12px;
        border-radius: 3px;
        border: 1px solid @border-gray;
        font-size: 14px;
        font-family: 'AvenirLTStd-Book', sans-serif;
        color: @light-gray;
        outline: none;

        &.ng-invalid.ng-touched {
            border-color: @red;
        }
    }

    .custom-radio {
        display: none;

        + .custom-label {
            cursor: pointer;

            span {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin: -1px 4px 0 0;
                vertical-align: middle;
                -moz-border-radius: 50%;
                border-radius: 50%;
                border: 4px solid #eee;
                background-color: #eee;
                cursor: pointer;
            }
        }

        &:checked {
            + .custom-label {
                span {
                    background-color: @blue;
                }
            }
        }
    }

    .custom-select {
        padding: 9px 8px;
        border-radius: 3px;
        border: 1px solid @border-gray;
        font-size: 14px;
        font-family: 'AvenirLTStd-Heavy', sans-serif;
        color: @dark-gray;
        outline: none;
        cursor: pointer;

        &.ng-invalid.ng-touched {
            border-color: @red;
        }
    }

    dx-select-box.custom-select,
    dx-tag-box.custom-select {
        padding: 5px 8px;
    }

    .custom-check-box {
        width: 16px;
        height: 16px;
        border-radius: 3px;
        border: 1px solid @border-gray;
        background-color: transparent;
    }

    .custom-textarea {
        padding: 10px 12px;
        border-radius: 3px;
        border: 1px solid @border-gray;
        font-size: 14px;
        font-family: 'AvenirLTStd-Heavy', sans-serif;
        color: @dark-gray;
        outline: none;
        resize: none;

        &.ng-invalid.ng-touched {
            border-color: @red;
        }
    }

    .text-danger {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 12px;
        color: @red;
    }
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff url('assets/images/loader.gif') center no-repeat;
    opacity: 0.9;
    z-index: 9999;

    &.dark-loader {
        background: @dark-black url('assets/images/loader.gif') center no-repeat;
        opacity: 0.4;
    }
}

.custom-button {
    width: 145px;
    height: 45px;
    border: 1px solid @border-gray;
    border-radius: 3px;
    text-align: center;
    font-family: 'AvenirLTStd-Black', sans-serif;
    color: #b7bdca;
    background-color: #f9fafc;
    cursor: pointer;

    &:first-child {
        margin-right: 5px;
    }

    &:hover {
        background-color: darken(#f9fafc, 5%);
    }

    &.blue {
        border: none;
        color: @w;
        background-color: @blue;

        &:hover {
            background-color: darken(@blue, 5%);
        }
    }

    &.green {
        width: 70px;
        height: 25px;
        border: none;
        color: @w;
        margin-left: 45px;
        margin-right: 10px;
        background-color: @green;
    }

    &.purple {
        width: 70px;
        height: 25px;
        border: none;
        color: @w;
        margin-right: 10px;
        background-color: @purple;
    }

    &.pink {
        width: 70px;
        height: 25px;
        border: none;
        color: @w;
        margin-left: 45px;
        margin-right: 10px;
        background-color: @pink;
    }

    &.red {
        border: none;
        color: @w;
        background-color: @red;

        &:hover {
            background-color: darken(@red, 5%);
        }
    }

    &.orange {
        border: none;
        color: @w;
        background-color: #f6a812;

        &:hover {
            background-color: darken(#f6a812, 5%);
        }
    }

    &.smaller {
        //width: 120px;
        width: 130px;
        height: 37px;
    }

    &[disabled] {
        opacity: 0.5;
        cursor: no-drop;

        &:hover {
            background-color: @blue;
        }
    }
}

.dark-theme {
    .dx-datagrid-summary-item {
        color: #fff;
    }
}

input[type='checkbox'].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='checkbox'].css-checkbox + label.css-label,
input[type='checkbox'].css-checkbox + label.css-label.clr {
    padding-left: 23px;
    height: 18px;
    display: inline-block;
    line-height: 18px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 18px;
    vertical-align: sub;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

input[type='checkbox'].css-checkbox:checked + label.css-label,
input[type='checkbox'].css-checkbox + label.css-label.chk {
    background-position: 0 -18px;
}

label.css-label {
    background-image: url('assets/images/checkbox.png');
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//endregion

//region Random
h2 {
    font-family: 'AvenirLTStd-Heavy', sans-serif;
}

.right-arrow {
    color: #0096ff;
    font-size: 17px;
    margin-left: -50px;
}

/* scrollbar styling */
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-button {
    width: 4px;
    height: 0;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background: #0096ff;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #0087e6;
}

/* The switch - the box around the slider */
.switch {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: @w;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

//endregion

//region Devex overrides
dx-date-box {
    .dx-texteditor-container {
        .dx-texteditor-input {
            //padding: 10px 24px;
            //padding: 10px 44px 10px 13px;
            padding: 9px 44px 9px 13px;
            font-size: 14px;
            font-family: 'AvenirLTStd-Book', sans-serif;
            color: @light-gray;
            outline: none;
        }

        .dx-dropdowneditor-button {
            width: 35px;

            .dx-dropdowneditor-icon {
                width: 33px;
            }
        }

        .dx-texteditor-buttons-container {
            position: absolute;
            top: 0;
            right: 0;
            width: auto;
            height: 100%;
        }
    }
}

.dx-calendar {
    .dx-calendar-navigator {
        .dx-button-content {
            .dx-button-text {
                color: black;
            }
        }
    }
}

.calendar-scheduler .dx-scheduler-work-space,
.calendar-scheduler .dx-scrollable-container {
    overflow: visible;
}

.calendar-scheduler .dx-scheduler-date-table-scrollable .dx-scrollable-content {
    overflow: visible;
}

.dx-scheduler-appointment[data-unitid-__45__1] {
    .base-price {
        .room-occupancy {
            display: none;
        }
    }
}

.link-invoice-grid {
    .dx-datagrid-rowsview .dx-row > td {
        white-space: normal !important;
    }
}

.ota-mapping-grid {
    .dx-datagrid-search-panel {
        display: none;
    }
}

dx-data-grid {
    width: 100%;
    margin: 35px 0;

    .dx-datagrid {
        .dx-datagrid-pager {
            padding-top: 12px;
            padding-bottom: 12px;
            border: none;
        }

        .dx-column-lines {
            > td {
                padding: 12px 0;
                border: none;
                border-bottom: 1px solid @border-light-gray;
                font-size: 12px;
                font-family: 'AvenirLTStd-Medium', sans-serif;
            }
        }

        .dx-datagrid-table {
            td {
                padding: 8px 5px !important;
                border: none;
                border-bottom: 1px solid #eef1f5;
                font-size: 14px;
                font-family: 'AvenirLTStd-Medium', sans-serif;
                color: @dark-gray;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .dx-datagrid-headers {
            border-top: 1px solid #eef1f5;
            border-bottom: 1px solid #eef1f5;
            background-color: #fbfbfc;

            .dx-datagrid-table {
                .dx-row {
                    > td {
                        padding: 7px 5px !important;
                        border-bottom: 1px solid #eef1f5;
                    }
                }
            }

            td {
                padding: 6px 0;
                border-bottom: 1px solid #eef1f5;
                text-align: left;
                text-transform: uppercase;
                font-size: 12px;
                font-family: 'AvenirLTStd-Heavy', sans-serif;
                color: @light-gray;
            }

            &.dx-datagrid-nowrap {
                .dx-header-row {
                    > td {
                        > .dx-datagrid-text-content {
                            font-family: 'AvenirLTStd-Heavy', sans-serif;
                        }
                    }
                }
            }
        }
    }

    .dx-datagrid-header-panel {
        margin-bottom: 20px;
        border: none;
    }

    .dx-datagrid-rowsview {
        border: none;

        .dx-row {
            &:nth-child(even) {
                background-color: #fbfbfc;
            }

            > td {
                font-size: 14px;
                color: @dark-gray;

                span {
                    text-decoration: underline;
                    color: @blue;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .dx-texteditor {
        border-radius: 3px;
        border: 1px solid @border-gray;
    }

    .dx-texteditor-container {
        .dx-texteditor-input {
            //padding: 10px 24px;
            padding: 4px 24px;
            font-size: 13px;
            font-family: 'AvenirLTStd-Book', sans-serif;
            color: @light-gray;
            outline: none;
        }
    }

    .dx-toolbar {
        .dx-toolbar-items-container {
            height: 36px;
        }

        // move search box to right side
        .dx-toolbar-after {
            //left: 0;
            padding: 0;
            left: auto;
            right: 0;

            .dx-datagrid-search-panel {
                margin: 0;
            }
        }
    }
}

.dx-dropdownlist-popup-wrapper.dx-popup-wrapper .dx-overlay-content.dx-dropdowneditor-overlay-flipped {
    height: 380px !important;
}

dx-data-grid .dx-datagrid .dx-column-lines > td {
    padding: 12px 5px !important;
}

.room-type-grid {
    .dx-datagrid-rowsview .dx-row > td {
        white-space: normal;
    }
}

.promo-codes-grid {
    .dx-datagrid-rowsview .dx-row > td {
        vertical-align: middle !important;
    }
}

.account-wrapper {
    .dx-popup-content {
        white-space: normal !important;
    }
}

//region Calendar overrides
.dx-scheduler-view-switcher {
    display: none !important;
}

.dx-scheduler-appointment {
    height: 31px !important;
}

.dx-scheduler-appointment[data-unitid-__45__1] {
    height: auto !important;
    line-height: 17px;

    .dx-scheduler-appointment-content {
        padding: 1px 7px;
    }
}

.dx-scheduler-group-row {
    .dx-scheduler-group-header {
        height: 30px !important;

        .dx-scheduler-group-header-content {
            height: 29px !important;
        }
    }

    &:first-of-type {
        .dx-scheduler-group-header {
            //height: 29px !important;
            height: 49px !important;
        }
    }
}

.dx-scheduler-timeline {
    .dx-scheduler-header-panel-cell,
    .dx-scheduler-date-table-cell {
        /*width: 52px !important;*/
        width: 47px !important;
        font-size: 10px;
    }
}

.dx-scheduler-group-table,
.dx-scheduler-date-table {
    tr {
        &:nth-child(odd) {
            background-color: #fbfbfc;
        }

        &:first-of-type {
            background-color: #e5f4ff;
        }

        &:last-of-type {
            background-color: #dcffef;
        }
    }
}

.dx-scheduler-all-day-table-cell.dx-state-hover,
.dx-scheduler-date-table-cell.dx-state-hover {
    background-color: initial !important;
}

.dx-scheduler-cell-sizes-horizontal,
.dx-scheduler-cell-sizes-vertical {
    height: 30px !important;
}

.dx-scheduler-date-table-row {
    &:first-of-type {
        .dx-scheduler-cell-sizes-horizontal,
        .dx-scheduler-cell-sizes-vertical {
            height: 50px !important;
        }
    }
}

.calendar-scheduler {
    .dx-scheduler-header {
        display: flex;
        justify-content: flex-end;
    }
}

.dx-scheduler-date-time-indicator:before {
    z-index: 1 !important;
}

.msg-text-editor {
    iframe {
        height: 650px !important;
    }
}

.room-desc-text-editor {
    iframe {
        height: 205px !important;
    }
}

.booking-search-grid {
    .dx-datagrid .dx-column-lines > td {
        padding: 5px !important;
        cursor: pointer;
    }

    .dx-datagrid-rowsview .dx-row {
        height: 30px;
    }
}

@media screen and (max-width: 1700px) {
    .dx-scheduler-timeline {
        .dx-scheduler-header-panel-cell,
        .dx-scheduler-date-table-cell {
            width: 40px !important;
        }
    }
}

@media screen and (max-width: 1500px) {
    .dx-scheduler-timeline {
        .dx-scheduler-header-panel-cell,
        .dx-scheduler-date-table-cell {
            width: 36px !important;
            font-size: 9px;
        }

        &.dx-scheduler-work-space-grouped {
            .dx-scheduler-group-table,
            .dx-scheduler-sidebar-scrollable,
            .dx-scheduler-sidebar-scrollable:before {
                width: 70px !important;
            }

            .dx-scheduler-date-table-scrollable,
            .dx-scheduler-header-scrollable {
                margin-left: 70px !important;
            }
        }

        .dx-scheduler-group-header {
            .dx-scheduler-group-header-content {
                div {
                    font-size: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .booking-search-grid {
        .dx-datagrid .dx-column-lines > td {
            font-size: 12px;
        }

        .dx-datagrid-rowsview .dx-row {
            height: 27px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .dx-scheduler-timeline {
        .dx-scheduler-header-panel-cell,
        .dx-scheduler-date-table-cell {
            width: 32px !important;
        }

        &.dx-scheduler-work-space-grouped {
            .dx-scheduler-group-table,
            .dx-scheduler-sidebar-scrollable,
            .dx-scheduler-sidebar-scrollable:before {
                width: 50px !important;
            }

            .dx-scheduler-date-table-scrollable,
            .dx-scheduler-header-scrollable {
                margin-left: 50px !important;
            }
        }

        .dx-scheduler-group-header {
            .dx-scheduler-group-header-content {
                div {
                    font-size: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .dx-scheduler-timeline {
        .dx-scheduler-header-panel-cell,
        .dx-scheduler-date-table-cell {
            width: 26px !important;
        }

        .dx-scheduler-header-panel-cell {
            word-spacing: 26px;
        }
    }
}

//endregion

.dx-state-disabled {
    background-color: #ebebe4 !important;

    input {
        background-color: #ebebe4;
    }
}

//.dx-datagrid-rowsview .dx-row {
//  height: 45px;
//}

.dx-datagrid-pager.dx-widget {
    color: @dark-gray !important;
}

.dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
    color: @w !important;
    border-color: transparent;
    background-color: #0396fc !important;
}

.dx-pager .dx-page-sizes .dx-page-size {
    padding-right: 8px !important;
}

dx-data-grid .dx-datagrid-rowsview .dx-row > td {
    color: @light-gray !important;
    font-family: 'AvenirLTStd-Book', sans-serif !important;
}

dx-data-grid .dx-datagrid .dx-datagrid-headers td {
    color: @dark-gray !important;
    font-family: 'AvenirLTStd-Book', sans-serif !important;
}

.dx-pager .dx-pages .dx-info {
    color: @light-gray !important;
    font-family: 'AvenirLTStd-Book', sans-serif !important;
}

.dx-dropdownlist-popup-wrapper .dx-popup-content {
    max-height: 385px;
}

.dark-theme {
    .dx-texteditor-input {
        color: #fff !important;
    }
}

.dx-texteditor-input {
    font-family: 'AvenirLTStd-Heavy', sans-serif !important;
    color: #222 !important;
    cursor: pointer !important;
}

.notes-box {
    .dx-texteditor-input {
        cursor: text !important;
    }
}

.dx-accordion-item-title:before {
    margin-top: 10px;
}

#finance,
#guest {
    td {
        vertical-align: middle;
    }
}

#dx-vat {
    .dx-datagrid-header-panel {
        margin-bottom: 20px;
    }

    td {
        vertical-align: middle;
    }
}

.center-td {
    text-align: center !important;
}

td[aria-label='ID Column'] {
    text-align: center !important;
}

td[aria-label='Order Column'] {
    text-align: center !important;
}

.extras-grid {
    td[aria-label='Qty Column'] {
        text-align: center !important;
    }
}

.invoice-grid {
    td[aria-label='Quantity Column'] {
        text-align: center !important;
    }

    td[aria-label='Price Column'] {
        text-align: center !important;
    }

    td[aria-label='Vat Amount Column'] {
        text-align: center !important;
    }

    td[aria-label='Total Column'] {
        text-align: center !important;
    }
}

//endregion

// caterpay iframe

.caterpay-wrapper {
    iframe {
        height: 480px !important;
        border: none;
    }

    &.larger {
        iframe {
            height: 880px !important;
        }
    }
}

//endregion

.input-group {
    display: flex;
    align-items: center;

    input {
        padding: 10px 12px;
        border-radius: 3px;
        border: 1px solid #cfd3dd;
        font-size: 14px;
        font-family: 'AvenirLTStd-Book', sans-serif;
        color: #666;
        outline: none;
        width: ~'calc(100% - 26px)';
    }
}

.scrollable-menu {
    width: 220px !important;

    .list-group-item {
        span {
            font-size: 12px;
        }
    }
}

// Shared components
.room-types-host {
    .room-type-wrapper {
        top: 60px;
        left: 300px;
        width: ~'calc(100% - 380px)' !important;
        height: ~'calc(100% - 60px)' !important;
    }
}

.assign-rooms-host {
    .assign-rooms-wrapper {
        top: 60px;
        left: 300px;
        width: ~'calc(100% - 300px)' !important;
        height: ~'calc(100% - 60px)' !important;
    }
}

.password-icon-connection {
    a {
        top: 24px !important;
    }
}

.add-new-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    height: 20px;
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid rgb(230, 235, 246);
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    position: relative;

    .img {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 100%;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background-color: #f3f3f3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        padding-left: 2px;
        
        i:hover {
            color: #f3f3f3;
        }
    }

    .text {
        color: #b6bdc9;
        width: 70px;
        text-align: center;
        font-weight: bold;
    }

    &:hover {
        border-color: #0096ff;

        .img {
            background-color: #0096ff;
        }
        .text {
            color: #0096ff;
        }
    }
}

.dx-popup-normal[role='tooltip'] {
    background-color: @blue !important;
    //width: 200px !important;

    .dx-popup-content {
        width: 100% !important;

        p {
            white-space: normal;
        }
    }
}

.dx-datagrid-focus-overlay {
    border: 0px !important;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
    height: auto !important;
    min-height: 38px !important;
    white-space: normal !important;
}

.dx-popover-arrow:after {
    background-color: @blue !important;
}

.message-template-guest-body {
    p,
    div,
    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0;
    }
}
.tab.active:after {
    box-shadow: none !important;
}

.message-templates-guest-grid {
    .dx-datagrid-rowsview .dx-row {
        cursor: pointer;
    }
}

// End region

// Messaging center - logs set img width for html displayed in a modal
.message {
    margin-bottom: 60px;

    p > img {
        max-width: 50%;
    }

    a {
        color: lightskyblue;
    }
}

@media screen and (max-width: 1366px) {
    .room-types-host {
        .room-type-wrapper {
            top: 60px;
            left: 220px;
            width: ~'calc(100% - 300px)' !important;
            height: ~'calc(100% - 60px)' !important;
        }
    }

    .assign-rooms-host {
        .assign-rooms-wrapper {
            margin-bottom: 30px;
            margin-top: 80px !important;
            top: 90px;
            left: 220px;
            width: ~'calc(100% - 220px)' !important;
            height: ~'calc(100% - 80px)' !important;
        }
    }
}

@media screen and (max-width: 1024px) {
    .room-types-host {
        .room-type-wrapper {
            margin-top: 80px !important;
        }
    }

    .assign-rooms-host {
        .assign-rooms-wrapper {
            margin-top: 80px !important;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .pricing-plans-wrapper {
        .custom-box {
            .days-heading {
                .left-part {
                    .header {
                        height: 96% !important;
                        padding: 0 35px 0 0 !important;
                    }
                }
            }
        }
    }

    .rate-schedules-wrapper,
    .rate-plans-wrapper,
    .cost-center,
    .billing-wrapper,
    .users-wrapper {
        .custom-box {
            position: relative;
            top: 200px;

            &.popup {
                position: relative !important;
                top: 0 !important;
            }
        }
    }

    .rate-schedules-ie {
        .custom-box {
            top: 0;
        }
    }

    .promo-codes-wrapper {
        .custom-box {
            .filter-wrapper {
                height: 159px !important;
            }
        }
    }

    .pricing-plans-wrapper {
        .overlay .custom-box {
            position: relative !important;

            .form-wrapper {
                width: 500px !important;
            }
        }
    }

    .account-wrapper {
        .custom-box {
            position: relative !important;
        }
    }
}

// calendar
.dx-popover-wrapper {
    .dx-overlay-content {
        background: @blue !important;
    }
}

.img-actions .inner-function-tooltip .function-name {
    margin-left: 30px;
    cursor: pointer;
    color: @blue !important;
}

.close-function-popover {
    cursor: pointer;
    float:right;
}

.calendar-widget-wrapper {
    margin-top: 50px;

    .flex {
        display: flex;
        align-items: center;
    }

    .wrapper {
        display: flex;
        justify-content: center;
        padding: 0 5%;
    }

    .calendar {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .ui-icon-gripsmall-diagonal-se {
        display: none !important;
    }

    .row-name {
        width: 90px;
        padding: 0;
        height: 28px;
        min-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        color: #222;
        font-size: 13px;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;
        text-align: center;

        &.total {
            background-color: #bcdfcf;
        }
    }

    .left-side {
        div {
            &[data-row-id='Shuffle'] {
                border-top: 2px solid #dddddd;
            }
            &[id='0'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='1'] {
                border-top: 1px solid #dddddd;
            }
            &[id='5'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='10'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='15'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='20'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='25'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='30'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='35'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='40'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='45'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='50'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='55'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='60'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='65'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='70'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='75'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='80'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='85'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='90'] {
                border-bottom: 2px solid #dddddd;
            }
            &[id='95'] {
                border-bottom: 2px solid #dddddd;
            }
        }
    }

    .calendar-row {
            &[data-row-id='Shuffle'] {
                border-top: 1px solid #dddddd;
            }
            &[id^='1-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='2-'] {
                border-top: 1px solid #dddddd;
            }
            &[id^='6-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='11-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='16-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='21-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='26-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='31-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='36-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='41-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='46-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='51-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='56-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='61-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='66-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='71-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='76-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='81-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='86-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id^='91-'] {
                border-bottom: 1px solid #dddddd;
            }
            &[id='96-'] {
                border-bottom: 1px solid #dddddd;
            }
        }
    

    .row-name-week-view {
        width: 90px;
        padding: 0;
        height: 28px;
        min-height: 25px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        justify-content: flex-start;
        background-color: #fff;
        color: #222;
        font-size: 13px;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;
        text-align: center;

        &[data-row-id='Diary'] {
            border-bottom: 1px solid #dddddd;
        }
        &[data-row-id='Shuffle'] {
            border-top: 1px solid #dddddd;
        }
        &:nth-child(5n + 1) {
            //border-bottom: 1px solid #dddddd;
        }
        &.total {
            background-color: #bcdfcf;
        }
    }

    .function[data-status="Unavailable"] {
        background-color: @calendar-function-unavailable;
        cursor: unset;
    }

    .function {
        position: relative;
        background-color: @calendar-function-available;
        color: black;
        //padding: 3px 0;
        //height: 19px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        transition: all 280ms ease;
        margin: 0px;
        //border-radius: 10px;
        //border-top: 3px solid #007fff;  
        
        .cal-name {
            margin: 0;
            min-width: 40px;
            text-align: left;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            height: 100%;
            line-height: 25px;
            font-size: 11px;
        
        }
    }

    .function-session-available {
        background-color: @calendar-function-available;
        width: calc(33% - 1px);
        float:left;

    }
    .function-session-unavailable {
        background-color: @calendar-function-unavailable;
        width: calc(33% - 1px);
        float:left;

    }
    .function-session-booked {
        background-color: @calendar-function-booked;
        width: 33%;
        float:left;
    }

    @keyframes calendar-quick-booking-kf {
        0% {
            box-shadow: none;
        }
        50% {
            box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
        }
        100% {
            box-shadow: none;
        }
     }

     @keyframes calendar-quick-booking-dark-kf {
        0% {
            box-shadow: none;
        }
        50% {
            box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.85);
        }
        100% {
            box-shadow: none;
        }
     }

    .calendar-quick-booking {
        -webkit-animation:calendar-quick-booking-kf ease-in-out 3s 1;
        animation:calendar-quick-booking-kf ease-in-out 3s 1;
    }

    .calendar-quick-booking-dark {
        -webkit-animation:calendar-quick-booking-dark-kf ease-in-out 3s 1;
        animation:calendar-quick-booking-dark-kf ease-in-out 3s 1;   
    }

    .draggable {
        position: relative;
        background-color: #007fff;
        color: black;
        padding: 3px 0;
        height: 19px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        transition: all 280ms ease;
        margin: 0px 2px 1px 0;
        border-radius: 10px;
        border-top: 3px solid #007fff;

        .cal-name {
            margin: 0 5px;
            min-width: 40px;
            text-align: left;
            width: 85%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            height: 25px;
            line-height: 25px;
            font-size: 11px;
        
        }

        .cal-room-info {
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .cal-room-type {
                margin-right: 10px;
            }

            .cal-guest-number {
                background: #fff;
                color: #000;
                padding: 3px 5px;
            }
        }

        &.display-none {
            .cal-room-info {
                display: none;
            }
        }

        &.to-arrive {
            background-color: @calendar-to-arrive;
            border-top: 3px solid @calendar-to-arrive;

            &.fully-paid {
                color: #000;
                background: repeating-linear-gradient(
                    45deg,
                    @calendar-to-arrive -0.5px,
                    rgba(255, 255, 255, 1) 0px,
                    @calendar-to-arrive 0.9px,
                    @calendar-to-arrive 3px
                );
            }
        }

        &.enquired {
            background-color: @calendar-enquired;
            border-top: 3px solid @calendar-enquired;

            &.fully-paid {
                color: #000;
                background: repeating-linear-gradient(
                    45deg,
                    @calendar-enquired -0.5px,
                    rgba(255, 255, 255, 1) 0px,
                    @calendar-enquired 0.9px,
                    @calendar-enquired 3px
                );
            }
        }

        &.checked-in {
            background-color: @calendar-checked-in;
            border-top: 3px solid @calendar-checked-in;

            &.fully-paid {
                color: #000;
                background: repeating-linear-gradient(
                    45deg,
                    @calendar-checked-in -0.5px,
                    rgba(255, 255, 255, 1) 0px,
                    @calendar-checked-in 0.9px,
                    @calendar-checked-in 3px
                );
            }
        }

        &.checked-out {
            background-color: @calendar-checked-out;
            border-top: 3px solid @calendar-checked-out;

            &.fully-paid {
                color: #000;
                background: repeating-linear-gradient(
                    45deg,
                    @calendar-checked-out -0.5px,
                    rgba(255, 255, 255, 1) 0px,
                    @calendar-checked-out 0.9px,
                    @calendar-checked-out 3px
                );
            }
        }
        &.warningInfoError {
            background-color: @warningInfoError;
            border-top: 3px solid @warningInfoError;
        }

        &.maintenance {
            background-color: @calendar-maintenance;
            border-top: 3px solid @calendar-maintenance;
        }

        &.unavailable {
            background-color: @calendar-unavailable;
            border-top: 3px solid @calendar-unavailable;
        }

        &.event-draggable {
            background-color: @calendar-events;
            border-top: 3px solid @calendar-events;
        }
    }

    .ui-draggable-dragging,
    .ui-resizable-resizing {
        opacity: 0.5 !important;
        z-index: 2;
    }

    .calendar-row {
        display: flex;
        &:nth-child(5n + 1) {
            //border-bottom: 1px solid #dddddd;
        }
        &[data-row-id='-1'] {
            border-top: 2px solid #dddddd;
        }
    }

    .days-row {
        display: flex;
    }

    .calendar-cells {
        overflow: hidden;
    }

    .calendar-row .ui-selecting {
        background-color: #ff2828 !important;
    }

    .calendar-row .ui-selected {
        background-color: #404dff !important;
        color: white;
    }

    .calendar-row-total {
        .day,
        .day-week-view,
        .seven-grid {
            background-color: #bcdfcf !important;
        }
    }

    .cell-box {
        height: 28px;
        min-height: 25px;
        background-color: #fff;
        color: #222 !important;
        font-size: 10px;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        text-align: center;

        &.weekend {
            background-color: #f5f5f5;
        }
    }

    .day,
    .date {
        width: 50px;
        .cell-box;
    }

    .day-week-view,
    .date-week-view {
        width: 101px;
        .cell-box;
    }

    .seven-grid,
    .date-seven-view {
        width: 220px;
        .cell-box;
    }

    .day-total {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .date {
        border-top: 1px solid #eee;
        // display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .date-week-view,
    .date-seven-view {
        border-top: 1px solid #eee;
        // display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .day:nth-child(odd),
    .date:nth-child(odd) {
        width: 50.1px;
    }

    .day-week-view:nth-child(odd),
    .date-week-view:nth-child(odd) {
        width: 101.2px;
    }

    .seven-grid:nth-child(odd),
    .date-seven-view:nth-child(odd) {
        width: 220.1px;
    }

    .diary .day .day-week-view {
        height: 25px;
    }

    &.dark-theme {
        .calendar-row-total {
            .day {
                background-color: #bcdfcf !important;
                color: #000000 !important;
            }

            .day-week-view {
                background-color: #bcdfcf !important;
                color: #000000 !important;
            }
        }

        .row-name {
            background-color: #2f3439 !important;
            color: #fff !important;

            &.total {
                background-color: #16191c !important;
            }
        }

        .row-name-week-view {
            background-color: #2f3439 !important;
            color: #fff !important;

            &.total {
                background-color: #bcdfcf !important;
                color: #000000 !important;
            }
        }

        .seven-grid,
        .day,
        .date {
            background-color: #2f3439;
            color: #fff !important;

            &.weekend {
                background-color: #5a5a5a;
            }
        }

        .seven-grid,
        .day-week-view,
        .date-week-view {
            background-color: #2f3439;
            color: #fff !important;

            &.weekend {
                background-color: #5a5a5a;
            }
        }
    }
}

.ui-resizable-e {
    width: 15px !important;
}

.ui-resizable-s {
    display: none !important;
}

.ui-resizable-handle {
    z-index: 1 !important;
}

.ui-selectable-helper {
    visibility: hidden !important;
}

.bold {
    font-weight: bold;

    .dx-header-row {
        display: none;
    }
}

.checkbox-wrap {
    .dx-state-disabled.dx-checkbox .dx-checkbox-icon,
    .dx-state-disabled .dx-checkbox .dx-checkbox-icon {
        opacity: 1 !important;
    }
}

//Removing spinners on inputs for firefox
input[type='number'] {
    -moz-appearance: textfield;
}

.dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
    display: inline !important;
    visibility: hidden;
}

.dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td:hover > .dx-select-checkbox {
    display: inline !important;
    visibility: visible;
}

.dx-switch-on {
    //color: transparent !important;
}

.dx-switch-off {
    //color: transparent !important;
}

.dx-switch-handle:before {
    background-color: @red !important;
}

.dx-switch-on-value .dx-switch-handle:before {
    background-color: @green !important;
}

@media screen and (max-width: 1750px) {
    .calendar-widget-wrapper {
        .day,
        .date {
            width: 45px;
        }

        .day-week-view,
        .date-week-view {
            width: 91px;
        }

        .day:nth-child(odd),
        .date:nth-child(odd) {
            width: 45.1px;
        }

        .day-week-view:nth-child(odd),
        .date-week-view:nth-child(odd) {
            width: 91.2px;
        }

        .seven-grid,
        .date-seven-view {
            width: 190px;
        }

        .seven-grid:nth-child(odd),
        .date-seven-view:nth-child(odd) {
            width: 190.1px;
        }

        .row-name {
            width: 65px;
        }

        .row-name-week-view {
            width: 130px;
        }
    }
}

@media screen and (max-width: 1550px) {
    .calendar-widget-wrapper {
        .day,
        .date {
            width: 40px;
        }

        .day-week-view,
        .date-week-view {
            width: 81px;
        }

        .day:nth-child(odd),
        .date:nth-child(odd) {
            width: 40.1px;
        }

        .day-week-view:nth-child(odd),
        .date-week-view:nth-child(odd) {
            width: 81.2px;
        }

        .seven-grid,
        .date-seven-view {
            width: 165px;
        }

        .seven-grid:nth-child(odd),
        .date-seven-view:nth-child(odd) {
            width: 165.1px;
        }

        .row-name {
            width: 60px;
        }

        .row-name-week-view {
            width: 120px;
        }
    }
}

@media screen and (max-width: 1400px) {
    .calendar-widget-wrapper {
        .day,
        .date {
            width: 35px;
        }

        .day-week-view,
        .date-week-view {
            width: 71px;
        }

        .day:nth-child(odd),
        .date:nth-child(odd) {
            width: 35.1px;
        }

        .day-week-view:nth-child(odd),
        .date-week-view:nth-child(odd) {
            width: 71.2px;
        }

        .seven-grid,
        .date-seven-view {
            width: 145px;
        }

        .seven-grid:nth-child(odd),
        .date-seven-view:nth-child(odd) {
            width: 145.1px;
        }

        .row-name {
            width: 55px;
            font-size: 11px;
        }

        .row-name-week-view {
            width: 110px;
            font-size: 11px;
        }
    }
}

@media screen and (max-width: 1250px) {
    .calendar-widget-wrapper {
        .day,
        .date {
            width: 32px;
            font-size: 9px;
        }

        .day-week-view,
        .date-week-view {
            width: 65px;
        }

        .day:nth-child(odd),
        .date:nth-child(odd) {
            width: 32.1px;
        }

        .day-week-view:nth-child(odd),
        .date-week-view:nth-child(odd) {
            width: 65.2px;
        }

        .seven-grid,
        .date-seven-view {
            width: 130px;
        }

        .seven-grid:nth-child(odd),
        .date-seven-view:nth-child(odd) {
            width: 130.1px;
        }

        .row-name {
            width: 40px;
            font-size: 10px;
        }

        .row-name-week-view {
            width: 80px;
            font-size: 10px;
        }
    }
}

@media screen and (max-width: 1150px) {
    .calendar-widget-wrapper {
        .day,
        .date {
            width: 28px;
            font-size: 8px;

            div:first-child {
                margin-top: 5px !important;
            }
        }

        .day-week-view,
        .date-week-view {
            width: 57px;
        }

        .day:nth-child(odd),
        .date:nth-child(odd) {
            width: 28.1px;
        }

        .day-week-view:nth-child(odd),
        .date-week-view:nth-child(odd) {
            width: 57.2px;
        }

        .seven-grid,
        .date-seven-view {
            width: 115px;
        }

        .seven-grid:nth-child(odd),
        .date-seven-view:nth-child(odd) {
            width: 115.1px;
        }

        .row-name {
            width: 40px;
            font-size: 10px;
        }

        .row-name-week-view {
            width: 80px;
            font-size: 10px;
        }
    }
}

@media screen and (max-width: 820px) {
    .cost-center-wrapper {
        .overlay {
            overflow: auto;
        }

        .custom-box {
            &.popup {
                position: absolute;
                top: 75px;
                margin-bottom: 75px;
            }
        }
    }

    .room-desc-text-editor {
        iframe {
            height: 195px !important;
        }
    }

    .rate-schedules-wrapper {
        .overlay {
            overflow: auto;
        }

        .custom-box {
            width: 500px;
            max-height: initial !important;
            position: absolute;
            top: 50px;
            margin-bottom: 50px;
        }
    }

    .partners-wrapper {
        .overlay {
            overflow: auto;
        }

        .custom-box {
            &.popup {
                position: absolute;
                top: 100px;
                margin-bottom: 100px;
                align-self: inherit;
            }
        }
    }
}
.only-print {
    display: none;
}

@media print {
    .only-print {
        display: inherit;
    }
    .dontPrint {
        display: none;
    }
    header {
        display: none;
    }
    .side-menu {
        display: none;
    }
    .room-type-print-grid {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        z-index: 20;
        overflow: auto;

        &.dark-theme {
            .only-print {
                color: black;
            }
            .total {
                color: #000 !important;
            }
            .dx-datagrid {
                background-color: #fff;
                .dx-datagrid-table {
                    td {
                        color: #000 !important;
                    }
                }
                .dx-datagrid-headers {
                    background-color: #fff;
                }
                .dx-datagrid-rowsview .dx-row:nth-child(even) {
                    background-color: #fff;
                }

                .to-arrive {
                    color: #0096ff !important;
                }
            }
            .dx-template-wrapper {
                color: #000 !important;
            }
        }
    }
    .edit-print-page {
        display: none;
    }
}

.data-grid-transparent {
    background-color: transparent !important;

    > .dx-datagrid {
        background-color: transparent !important;

        .dx-datagrid-rowsview .dx-row:nth-child(even) {
            background-color: transparent !important;
        }
    }

    .dx-datagrid-headers {
        background-color: transparent !important;
    }
}

.text-white {
    color: white !important;
}

.fl {
    float:left;
}

.clear {
    clear: both;
}

.m-0 {
    margin: 0px !important;
}

.mt-0 {
    margin-top: 0px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-50 {
    margin-top: 50px;
}

.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-50 {
    margin-right: 50px;
}

.mb-0 {
    margin-bottom: 0px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-50 {
    margin-bottom: 50px;
}

.ml-1 {
    margin-left: 1px;
}

.ml-2 {
    margin-left: 2px;
}

.ml-3 {
    margin-left: 3px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-50 {
    margin-left: 50px;
}


.w-10p {
    width: 10% !important;
}

.w-17p {
    width: 17% !important;
}

.w-20p {
    width: 20% !important;
}

.w-23p {
    width: 23% !important;
}

.w-25p {
    width: 25% !important;
}

.w-30p {
    width: 30% !important;
}

.w-33p {
    width: 33% !important;
}

.w-37p {
    width: 37% !important;
}

.w-40p {
    width: 40% !important;
}

.w-48p {
    width: 48% !important;
}

.w-50p {
    width: 50% !important;
}

.w-60p {
    width: 60% !important;
}

.w-70p {
    width: 70% !important;
}

.w-80p {
    width: 80% !important;
}

.w-90p {
    width: 90% !important;
}

.w-100p {
    width: 100% !important;
}

.w-25 {
    width: 25px !important;
}

.w-50 {
    width: 50px !important;
}

.w-100 {
    width: 100px !important;
}

.w-200 {
    width: 200px !important; 
}

div.spacer-s {
    height: 10px;
}
div.spacer-m {
    height: 20px;
}
div.spacer-l {
    height: 30px;
}

.pdf-icon {
    cursor: pointer;
    background-image: url('assets/images/PDF_icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
}

.csv-icon {
    cursor: pointer;
    background-image: url('assets/images/CSV_icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
}

textarea.tox-textarea, .tox-textarea-wrap {
    height: 100% !important;
}

.fa-icon {
    cursor: pointer;

    &.on {
        opacity: 100;
    }
    &.off {
        opacity: 0.50;
    }

    &.dark-theme {
        color: white !important;
        opacity: 0.75;
    }

    &.red {
        color: @red !important;
    }
    &.blue {
        color: @blue !important;
    }
    &.dgreen {
        color: #009900 !important;
    }
    &.gray {
        color: #cfcfcf;;
    }
    &.dgray {
        color: @light-gray !important;
    }
    &.yellow {
        color: @calendar-to-arrive !important;
    }

    &:hover { 
        color: @blue;
        text-shadow: 0 0 1px #02022c;
    }

    &.link-icon {
        &:hover { 
            color: @dark-gray !important;
            text-shadow: none;
        } 
    }

    &.dark-theme {
        &.link-icon {
            &:hover { 
                color: white !important;;
                text-shadow: 0 0 1px #ccc;
            } 
        }
    }

    &.ninety-deg {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);    
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
            color: initial;

            .fa {
                color: initial;
            }
        }
    }
}

.fa-icon-vsmall {
    font-size: 14px;
}

.fa-icon-smaller {
    font-size: 16px;
}

.fa-icon-small {
    font-size: 18px;
}

.fa-icon-medium {
    font-size: 20px;
}

.fa-icon-large {
    font-size: 22px;
}

.fa-icon-larger {
    font-size: 26px;
}

.text-center {
    text-align: center;
}

.text-error {
    color: @warningInfoError;
}
.text-warning {
    color: @warningInfoWarning;
}
.text-info {
    color: @blue;
}

.not-allowed {
    cursor: not-allowed !important;
}
.fa-icon-vlarge {
    font-size: 35px;
}
.dx-tab-text {
    font-size: 14px;
}

.dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
    min-width: 180px;
}